import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentIndex: 0,
    bigModalPicture: 'S1-Dnevni-boravak-1.jpg',
    currentIndexAppartment: 0,
    imagesAppartment1: [
      "S1-Dnevni-boravak-2.jpg",
      "S1-Dnevni-boravak-3.jpg",
      "S1-Dnevni-boravak-4.jpg",
      "S1-Kuhinja.jpg",
      "S1-Soba.jpg"

    ],
                imagesAppartment2: [
      "S2-Dnevni-boravak-1.jpg",
      "S2-Dnevni-boravak-2.jpg",
      "S2-Kuhinja-1.jpg",
      "S2-Kuhinja-2.jpg",
      "S2-Soba1.jpg",
      "S2-Soba2.jpg"
    ],
                imagesAppartment3: [
      "S3-Dnevni-boravak-1.jpg",
      "S3-Dnevni-boravak-2.jpg",
      "S3-Kuhinja.jpg",
      "S3-Soba1.jpg",
      "S3-Soba2.jpg"
    ],
    appartmentImages: [
      "S1-Dnevni-boravak-2.jpg",
      "S1-Dnevni-boravak-3.jpg",
      "S1-Dnevni-boravak-4.jpg",
      "S1-Kuhinja.jpg",
      "S1-Soba.jpg"

    ]
  },
  mutations: {
    setCurrentIndex(state, payload){
      state.currentIndex = payload
    },
    setCurrentIndexAppartment(state, payload){
      state.currentIndexAppartment = payload
    },
    setBigModalPicture(state, payload){
      state.bigModalPicture = payload
    },
    setAppartmentImages(state, payload){
      if(payload == 1) state.appartmentImages = state.imagesAppartment1
      if(payload == 2) state.appartmentImages = state.imagesAppartment2
      if(payload == 3) state.appartmentImages = state.imagesAppartment3
    }
  },
  actions: {
  },
  modules: {
  }
})
