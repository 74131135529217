<template>
  <div class="main-div">
    <router-link :to="{ name: 'Home' }">
      <img class="logo" src="../assets/vojislava-ilica-33-logo.svg" />
    </router-link>

    <ul class="navbar-list bigScreens">
      <router-link :to="{ name: 'Location' }">
        <li class="navbar-list-item" :class="{ underlined: $route.name == 'Location' }">LOKACIJA</li>
         <!-- <div class="underlined"></div> -->
      </router-link>
      <router-link :to="{ name: 'Project' }">
        <li class="navbar-list-item" :class="{ underlined: $route.name == 'Project' }">PROJEKAT</li>
      </router-link>

      <router-link :to="{ name: 'Apartments' }">
        <li class="navbar-list-item" :class="{ underlined: $route.name == 'Apartments' }">STANOVI</li>
      </router-link>
      <router-link :to="{ name: 'Apperance' }">
        <li class="navbar-list-item" :class="{ underlined: $route.name == 'Apperance' }">IZGLED OBJEKTA</li>
      </router-link>
      <router-link :to="{ name: 'Materials' }">
        <li class="navbar-list-item" :class="{ underlined: $route.name == 'Materials' }">MATERIJALI</li>
      </router-link>
      <router-link :to="{ name: 'Investitor' }">
        <li class="navbar-list-item" :class="{ underlined: $route.name == 'Investitor' }">INVESTITOR</li>
      </router-link>
      <router-link :to="{ name: 'Contact' }">
        <li class="navbar-list-item" :class="{ underlined: $route.name == 'Contact' }">KONTAKT</li>
      </router-link>
      <router-link :to="{ name: 'MoreInfo' }">
        <li class="navbar-list-item">
          <button class="information" :class="{ underlined: $route.name == 'MoreInfo' }">VIŠE INFORMACIJA</button>
        </li>
      </router-link>
    </ul>
    <div class="smallScreens">
      <div class="row firstLineSmall">
        <div class="hamburderDiv" @click="smallNav = !smallNav">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <ul v-show="smallNav" class="smallScrList">
          <router-link :to="{ name: 'Location' }">
            <li class="navbar-list-item" @click="smallNav = false">LOKACIJA</li>
          </router-link>
          <router-link :to="{ name: 'Project' }">
            <li class="navbar-list-item" @click="smallNav = false">PROJEKAT</li>
          </router-link>

          <router-link :to="{ name: 'Apartments' }">
            <li class="navbar-list-item" @click="smallNav = false">STANOVI</li>
          </router-link>
          <router-link :to="{ name: 'Apperance' }">
            <li class="navbar-list-item" @click="smallNav = false">IZGLED OBJEKTA</li>
          </router-link>
          <router-link :to="{ name: 'Materials' }">
            <li class="navbar-list-item" @click="smallNav = false">MATERIJALI</li>
          </router-link>
          <router-link :to="{ name: 'Investitor' }">
            <li class="navbar-list-item" @click="smallNav = false">INVESTITOR</li>
          </router-link>
          <router-link :to="{ name: 'Contact' }">
            <li class="navbar-list-item" @click="smallNav = false">KONTAKT</li>
          </router-link>
          <router-link :to="{ name: 'MoreInfo' }">
            <li class="navbar-list-item">
              <button class="information" @click="smallNav = false">VIŠE INFORMACIJA</button>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      smallNav: false,
    };
  },
};
</script>

<style  scoped>
@supports (-moz-appearance:none) { 
.navbar-list-item {
font-family: 'Nunito', sans-serif !important;
font-weight: 300 !important;
letter-spacing: 4px !important;
}
.information{
 font-family: 'Nunito', sans-serif !important;
 font-weight: 500 !important;
 letter-spacing: 2px !important;
}
}
.underlined{
  border-bottom: 4px solid #FA6400;

}
.information {
  height: 32.08px;
  width: 200px;
  border-radius: 16.04px;
  background-color: #fa6400;
  box-shadow: 0 4px 12px 0 #fa6400;
  border: none;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 3px;
  line-height: 9.62px;
  text-align: center;
}
.main-div {
  height: 64px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
}
.navbar-list {
  display: inline-block;
  list-style: none;
  padding-left: 13.5%;
  padding-top: 20px;
  margin-top: 0%;
}
.navbar-list-item {
  display: inline-block;
  margin-left: 70px;
  /* height: 24px; */
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 5px;
  line-height: 24px;
}
.logo {
  position: absolute;
  margin-top: 9px;
  margin-left: 20px;
}
@media (max-width: 1880px) {
  .navbar-list-item {
    margin-left: 60px;
  }
}
@media (max-width: 1790px) {
  .navbar-list-item {
    margin-left: 45px;
  }
}
@media (max-width: 1640px) {
  .navbar-list {
    padding-left: 10%;
  }
}
@media (max-width: 1578px) {
  .navbar-list-item {
    letter-spacing: 4px;
  }
}
@media (max-width: 1500px) {
  .navbar-list {
    padding-left: 13.5%;
  }
  .navbar-list-item {
    letter-spacing: 2px;
  }
}
@media (max-width: 1420px) {
  .navbar-list {
    padding-left: 10%;
  }
}
@media (max-width: 1360px) {
  .navbar-list-item {
    margin-left: 40px;
  }
}
@media (max-width: 1320px) {
  .navbar-list-item {
    margin-left: 35px;
  }
}
@media (max-width: 1270px) {
  .navbar-list-item {
    margin-left: 35px;
    letter-spacing: 1px;
  }
}
@media (max-width: 1270px) {
  .navbar-list-item {
    margin-left: 35px;
    letter-spacing: 1px;
  }
}
@media (max-width: 1200px) {
  .navbar-list-item {
    margin-left: 40px;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 1170px) {
  .navbar-list-item {
    margin-left: 45px;
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 1142px) {
  .navbar-list-item {
    margin-left: 40px;
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 22px;
  }
}
@media (max-width: 1100px) {
  .navbar-list-item {
    margin-left: 35px;
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 22px;
  }
  .logo{
    margin-left: 10px;
  }
}
@media (max-width: 1056px) {
  .navbar-list-item {
    margin-left: 34px;
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 22px;
  }
  .logo{
    margin-left: 5px;
  }
}
@media (max-width: 1050px) {
  .main-div-project-page{
position: fixed;
}
  .bigScreens {
    display: none;
  }
  .hamburderDiv {
    margin-top: 15px;
    padding: 5px;
    border-radius: 10px;
    width: 70px;
    position: absolute;
    right: 0 !important;
    z-index: 999;
    cursor: pointer;
  }
  .line {
    background-color: #000000;
    height: 5px;
    width: 70%;
    border-radius: 10px;
    margin-bottom: 6px;
  }
  .smallScrList {
    position: absolute;
    z-index: 998;
    width: 100% !important;
    height: 100%;
    background: rgba(255, 255, 255, 0.815);
    padding: 32px;
    padding-bottom: 16px;
    color: white;
    list-style: none;
    transition: 500ms ease-in !important;
    padding-top: 100px;
  }
  .navbar-list-item{
    display: block;
    margin-bottom: 30px;
    font-size: 20px;
  font-weight: bold;
  }
    .logo{
    margin-left: 20px;
  }
}
</style>