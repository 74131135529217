import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () =>
    import ( /* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/location',
    name: 'Location',
    component: () =>
    import ( /* webpackChunkName: "home" */ '../views/Location.vue')
  },
  {
    path: '/project',
    name: 'Project',
    component: () =>
    import ( /* webpackChunkName: "home" */ '../views/Project.vue')
  },
  {
    path: '/apartments',
    name: 'Apartments',
    component: () =>
    import ( /* webpackChunkName: "home" */ '../views/Apartments.vue')
  },
  {
    path: '/apperance',
    name: 'Apperance',
    component: () =>
    import ( /* webpackChunkName: "home" */ '../views/Apperance.vue')
  },
  {
    path: '/materials',
    name: 'Materials',
    component: () =>
    import ( /* webpackChunkName: "home" */ '../views/Materials.vue')
  },
  {
    path: '/investitor',
    name: 'Investitor',
    component: () =>
    import ( /* webpackChunkName: "home" */ '../views/Investitor.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
    import ( /* webpackChunkName: "home" */ '../views/Contact.vue')
  },
  {
    path: '/more-info',
    name: 'MoreInfo',
    component: () =>
    import ( /* webpackChunkName: "home" */ '../views/MoreInfo.vue')
  },
  { path: '*',
  component: () =>
  import ( /* webpackChunkName: "home" */ '../views/Home.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
