<template>
      <div class="footer">
      <ul class="navbar-list">
        <li class="navbar-list-item">zinvestingtim@gmail.com</li>
        <router-link :to="{name : 'Home'}">
        <li v-if="$route.name != 'Home'" class="navbar-list-item left-navbar-list-item">Nazad na pocetnu stranu</li>
        </router-link>
      </ul>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
@supports (-moz-appearance: none) {
  .navbar-list-item {
    font-family: "Nunito", sans-serif !important;
    font-weight: 500 !important;
  }
}
.navbar-list {
  background: white;
  display: inline-block;
  list-style: none;
  padding-left: 0px;
  padding-top: 12px;
  width: 100%;
}
.navbar-list-item {
  display: inline-block;
  margin-left: 70px;
  height: 24px;
  color: rgba(0, 0, 0, 0.507);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.left-navbar-list-item {
  float: right;
  margin-right: 2%;
}
.left-navbar-list-item-img {
  float: right;
  margin-right: 20px;
  margin-left: 40px !important;
}
@media (max-width: 724px) {
  .left-navbar-list-item-img{
    margin-left: 20px !important;
  }
.navbar-list-item{
margin-left: 50px;
}
}
@media (max-width: 560px) {
.navbar-list-item{
margin-left: 16px;
}
.left-navbar-list-item-img {
  float: right;
  margin-left: 20px !important;
}
}
@media (max-width: 502px) {
.navbar-list-item{
width: 100%;
text-align: center;
margin-top: 32px;
margin-left: 0%;
}
.left-navbar-list-item{
  margin-right: 0%;
}
}
</style>