<template>
  <div id="app">
<Navbar />
    <router-view/>
    <Footer />
  </div>
</template>
<script>
import Navbar from "../src/components/Navbar.vue"
import Footer from "../src/components/Footer.vue"
export default {
 components:{
   Navbar,
   Footer
 } 
}
</script>
<style>

</style>
